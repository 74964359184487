<template>
    <div class="pwl inner-body-block">
        <nuxt-link class="picture"  :to="item.href" v-for="(item, i) of data.pictures" :key="i">
            <img :src="'//cdn.triya.ru/upload/' + item.src" :loading="imageLoadingType"/>
            <span class="link">{{item.label}}</span>
        </nuxt-link>
        
    </div>
</template>

<script>
export default {
    props: ['data'],
    computed: {
        imageLoadingType() {
            if (this.data.flags.includes('eager')) {
                return 'eager';
            }

            return 'lazy';
        }
    }
}
</script>

<style lang="scss" scoped>

@import '~/assets/css/colors.scss';

.pwl {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 15px;
}

.picture {
    text-align: center;
}

.link {
    // font-weight: 500;
    color: $color-text;
    max-width: 140px;
}

img {
    aspect-ratio: 95 / 94;
    max-width: 95px;
    width: 100%;
}

</style>