import { Component, Vue, Prop as prop} from 'nuxt-property-decorator';

@Component
export default class PictureComponent extends Vue {
    @prop()
    public image;

    @prop({default: 0})
    public aspect;

    @prop()
    public additionalSizes: PictureAdditionalSize[];

    @prop()
    public size?: PictureSize;

    @prop({
        default: function() {
            return [1];
        }
    })
    public scales: number[];

    get sources() {

        let sizes = [];

        if (this.size) {
            sizes.push({
                media: null,
                size: this.size,
            })
        }

        let result = [];

        if (this.additionalSizes) {
            for (let size of this.additionalSizes) {
                sizes.push(size);
            }
        }

        for (let size of sizes) {

            let webp = [];
            let jpeg = [];

            for (let scale of this.scales) {

                const width = size.size.width*scale;
                const height = size.size.height*scale;

                let targetSize = `${width}x${height}`;
                if (width > 1920 || height > 1080) {
                    targetSize = 'original';
                }

                webp.push(`/thumbs/${targetSize}/${this.image}.webp ${scale}x`);
                jpeg.push(`/thumbs/${targetSize}/${this.image}.jpeg ${scale}x`);
            }

            result.push({
                media: size.media,
                srcset: webp.join(', '),
                type: 'image/webp'
            });

            result.push({
                media: size.media,
                srcset: jpeg.join(', '),
                type: 'image/jpeg'
            });
        }

        if (!this.size) {
            result.push({
                srcset: `/thumbs/original/${this.image}.webp`,
                type: 'image/webp'
            });
    
            result.push({
                srcset: `/thumbs/original/${this.image}.jpeg`,
                type: 'image/jpeg'
            });
        }


        return result;
    }


    get defaultSrc() {
        return `/thumbs/original/${this.image}.jpeg`;
    }
}