import { render, staticRenderFns } from "./index.vue?vue&type=template&id=c5046afa&scoped=true&functional=true"
var script = {}
import style0 from "./style.scss?vue&type=style&index=0&id=c5046afa&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "c5046afa",
  null
  
)

export default component.exports