import { Component, Watch as watch, Vue} from 'nuxt-property-decorator';
import { mapState, mapGetters } from 'vuex';

@Component({
    components: {
        landing: require('~/components/landing/').default,
        whyBuy: require('~/components/common/why-buy/').default,
        customPicture: require('~/components/common/very-simple-picture/').default,
        newsBlock: require('~/components/pages/index/news').default,
    },
    computed: {
        ...mapState(['region']),
        ...mapGetters({ regionCode: 'region' }),
    },
})
export default class IndexPageComponent extends Vue {

    public data: any = null;

    private head() {
        return {
            title: this.data.meta.title,
            meta: [
                { charset: 'utf-8' },
                { vmid: 'description', name: 'description', content: this.data.meta.description },
            ],
        };
    }

    private async asyncData({store, error}) {

        try {
            let data = await store.dispatch('data/FetchIndex');

            return {data};
        } catch (err) {
            error({
                statusCode: 500
            });
        }
    }

    @watch('regionCode')
    public async onRegionChange() {
        this.data = await this.$store.dispatch('data/FetchIndex');
    }
}

