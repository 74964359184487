import { render, staticRenderFns } from "./text-block.vue?vue&type=template&id=5475713b&scoped=true&functional=true"
var script = {}
import style0 from "./text-block.vue?vue&type=style&index=0&id=5475713b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "5475713b",
  null
  
)

export default component.exports