<template>
    <div class="catalog-offers-grid inner-body-block">
        <offer v-for="offer of data.offers" :key="offer.id"  :item="offer" class="no-border"></offer>
    </div>
</template>

<script>
export default {
    props: ['data'],
    components: {
        offer: require('~/components/catalog/offer/').default,
    }
}
</script>
