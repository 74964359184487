<template>
    <div class="widget-ref">
        
    </div>
</template>

<script>
export default {
    props: ['data'],
    data: () => ({
        observer: null
    }),
    mounted() {
        this.observer = new IntersectionObserver(this.observe);
        this.observer.observe(this.$el);
    },
    beforeDestroy() {
        this.observer?.disconnect();
    },
    methods: {
        observe(event) {
            if (Array.isArray(event) && event.length && event[0].isIntersecting) {
                this.observer?.disconnect();
                this.observer = null;
                this.trigger();
            }
        },
        async trigger() {
            const data = await this.$store.dispatch('data/GetWidget', {code: this.data.id});
            this.$emit('replace', {
                id: this.data.id,
                data
            });
        }
    }
}
</script>
