<template>
    <div class="landing-content">
        <template v-for="(banner, i) of blocks">
            <text-block class="inner-body-block" :key="i + 'text'" :data="banner" v-if="banner.type === 'text'"/>
            <picture-block class="inner-body-block" :key="i + 'pic'" :data="banner" v-else-if="banner.type === 'pictures'"/>
            <slider-block :key="i + 'slider'" :data="banner" v-else-if="banner.type === 'slider'"/>
            <offers-block :key="i + 'offers'" :data="banner" v-else-if="banner.type === 'offers'"/>
            <ref-block :key="i + 'ref'" :data="banner" v-else-if="banner.type === 'ref'" @replace="replaceRef"/>
                        <links-block :key="i + 'links'" :data="banner" v-else-if="banner.type === 'links'"/>
            <pictures-with-label-block :key="i + 'pwl'" :data="banner" v-else-if="banner.type === 'pictures-with-label'"/>
        </template>
    </div>
</template>

<script>

export default {
    components: {
        textBlock: require('./text-block.vue').default,
        pictureBlock: require('./picture-block.vue').default,
        sliderBlock: require('./slider-block/index.vue').default,
        offersBlock: require('./offer-block.vue').default,
        refBlock: require('./ref-block.vue').default,
        linksBlock: require('./links-block.vue').default,
        picturesWithLabelBlock: require('./pictures-with-label-block.vue').default,
    },
    props: ['blocks'],
    methods: {
        replaceRef(data) {
            const idx = this.blocks.findIndex((el) => el.id === data.id);
            if (idx !== -1) {
                this.blocks.splice(idx, 1);
            }

            this.blocks.push(...data.data);
        }
    }
}
</script>

<style lang="scss" src="./style.scss" scoped></style>