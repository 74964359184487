<template>
    <div class="picture-grid" :class="[`grid-${data.count}`, data.size]">
        <template v-for="(item, j) of data.pictures">
            <nuxt-link :key="j" :to="item.href" v-if="item.href && item.href.length">
                <img :src="'//cdn.triya.ru/upload/' + item.src" :loading="imageLoadingType"/>
            </nuxt-link>
            <img v-else :key="j + 'plain'" :src="'//cdn.triya.ru/upload/' + item.src" :loading="imageLoadingType"/>
        </template>
    </div>
</template>

<script>
export default {
    props: ['data'],
    computed: {
        displayImages() {
            if (!this.$store.state.webp) {
                return this.data.pictures;
            }
            return this.data.pictures.map(item => ({href: item.href, src: `${item.src}.webp`}));
        },
        imageLoadingType() {
            if (this.data.flags.includes('eager')) {
                return 'eager';
            }

            return 'lazy';
        }
    }
}
</script>

<style lang="scss" scoped>

@import '~/assets/css/colors.scss';

$bannerWidths: (
    1: 1450,
    2: 710,
    3: 464,
    4: 340,
    6: 215,
);
$bannerGap: (
    1: 0,
    2: 30,
    3: 29,
    4: 30,
    6: 32,
);

$bannerHeights: (
    'small': 100,
    'medium': 150,
    'medium-rare': 200,
    'large': 270,
    'slider': 400
);
.picture-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--gap-size);

    img {
        width: 100%;
        border-radius: 6px;
        // height: 0;
    }

    @each $size, $width  in $bannerWidths {
        &.grid-#{$size}{
            grid-template-columns: repeat($size, 1fr);
            gap: #{map-get($bannerGap, $size)}px;

            @each $name, $height in $bannerHeights {
                &.#{$name} img {
                    aspect-ratio: calc($width / $height);
                }
            }
        }
    }
}

</style>