import { FunctionalComponentOptions, RenderContext } from "vue";

const imageComponent: FunctionalComponentOptions = {
    functional: true,
    props: {
        image: {
            type: String,
            required: true,
        },
        eager: {
            type: Boolean,
            required: false,
        },
    },
    render(h, ctx: RenderContext) {

        const format = ctx.parent.$store.state.imageFormat;

        const src = `//cdn.triya.ru/thumbs/original/${ctx.props.image}.${format}`;

        return h('img', {
            class: `__picture ${ctx.data.class ?? ''} ${ctx.data.staticClass ?? ''}`.trim(),
            attrs: {
                src,
                loading: ctx.props.eager ? 'eager' : 'lazy'
            }
        });
    }
}

export default imageComponent;
