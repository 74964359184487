
import Vue from 'vue'

export default Vue.extend({
    props: ['news', 'youtube'],
    data: () => ({
        activeTab: 'news',
        tabs: [{
            code: 'news',
            icon: require('~/assets/icons/news/news.svg'),
            title: 'Новости',
        },{
            code: 'yt',
            icon: require('~/assets/icons/news/yt.svg'),
            title: 'Youtube',
        },
        ]
    })
})
