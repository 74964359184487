import { Component, Vue, Prop as prop, State} from 'nuxt-property-decorator';
import Swiper from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/vue'

@Component({
    components: {
        offer: require('~/components/catalog/offer/').default,
        textBlock: require('../text-block.vue').default,
        pictureBlock: require('../picture-block.vue').default,
        placeholderPicture: require('~/components/common/picture').default,
    },
})
export default class LandingSliderComponent extends Vue {

    @prop()
    public data!: any;

    mainGallery: Swiper = null;

    imageFlags: Set<string> = null;

    created() {
        if (this.data.content.type === 'pictures') {
            this.imageFlags = new Set(this.data.content.flags);
        }
    }

    mounted() {

        const flags = new Set(this.data.flags);

        this.mainGallery = new Swiper(this.$refs['carousel'] as HTMLElement, {
            spaceBetween: 36,
            watchOverflow: true,
            loop: flags.has('looped'),
            autoplay: flags.has('auto') ? {
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
                delay: 8000,
            } : false,
            slidesPerView: this.slides,
            slidesPerGroup: this.slides,
            breakpoints: {
                1000: {
                    spaceBetween: 24,
                },
                1400: {
                    spaceBetween: 30,
                },
            },
            navigation: {
                prevEl: this.$el.querySelector('.left.arrow') as HTMLElement,
                nextEl: this.$el.querySelector('.right.arrow') as HTMLElement,
            },
        });
    }

    get slides() {

        if (this.data.content.type === 'offers') {
            return 4;
        } else if (this.data.content.type === 'pictures') {
            return this.data.content.count;
        }

        return 1;
    }

    get computedClass() {
        if (this.data.style === 'weak') {
            return 'weak-body-block';
        } else {
            return 'inner-body-block';
        }
    }

    get displayImages() {

        if (!this.$store.state.webp) {
            return this.data.content.pictures;
        }

        return this.data.content.pictures.map(item => ({href: item.href, src: `${item.src}.webp`, placeholder: item.placeholder}));
    }

    get imageLoadingType() {
        if (this.imageFlags.has('eager')) {
            return 'eager';
        }

        return 'lazy';
    }
}
